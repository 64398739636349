// const axios = require('axios');
const URI = "https://www.ducamp-monod-paris.notaires.fr";
const API = "https://mailing-dma.onrender.com/dma/mails";

async function lSwitcher(_fr, _en, _sp) {
    let x = (localStorage.getItem('lang') == 0) ? await _fr : (localStorage.getItem('lang') == 1) ? await _en : await _sp;
    return x;
}

async function collapse(_e) {
    let [eTarget, arr] = [_e.target, document.getElementsByClassName('faq')];

    for(let i of arr){
        if(i.id == eTarget.nextSibling.id){
            i.classList.toggle('toggler__holder');
            eTarget.firstElementChild.children[0].classList.toggle('rotation--collapse');
        }
        else if(i.id != eTarget.nextSibling.id && i.classList.contains('toggler__holder')){
            i.classList.toggle('toggler__holder');
            i.previousSibling.firstElementChild.children[0].classList.remove('rotation--collapse');
        }
    }
}

// export { URI, API, axios, lSwitcher, collapse };
export { URI, API, lSwitcher, collapse };
