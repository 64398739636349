<template>
  <div
    id="mHamburger"
    class="mobile__trigger"
    @click="isMobileMenu"
    :class="isMenu ? 'collapse__hamb' : null"
  >
    <div class="mobile__trigger__hamburger">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";

const emit = defineEmits(["toggle-nav"]);
const props = defineProps(["call"]);
let isMenu = ref(false);

function isMobileMenu() {
  isMenu.value = !isMenu.value;
  emit("toggle-nav");
}

watchEffect(() => {
  if (props.call) isMenu.value = false;
});
</script>

<style></style>
