<template>
  <div class="container__footer--top"></div>

  <footer class="container__footer">
    <div class="container__footer--holder">
      <LogoMain @click="pRedirect()" class="footer--logo" />

      <div class="container__footer--links">
        <a :href="`${URI}/mentions-legales/#mention`">{{
          store.languageSwitcher == 0
            ? "Mentions légales"
            : store.languageSwitcher == 1
            ? "Legal mentions"
            : "Notas legales"
        }}</a>
        <a :href="`${URI}/mentions-legales/#confidentiality`">{{
          store.languageSwitcher == 0
            ? "Politique de confidentialité"
            : store.languageSwitcher == 1
            ? "Privacy policy"
            : "Política de confidencialidad"
        }}</a>
        <a :href="`${URI}/frais`">{{
          store.languageSwitcher == 0 ? "Frais" : store.languageSwitcher == 1 ? "Fees" : "Costos"
        }}</a>
        <a :href="`${URI}/nous-rejoindre`">{{
          store.languageSwitcher == 0
            ? "Nous rejoindre"
            : store.languageSwitcher == 1
            ? "Join us"
            : "Unirse a nosotros"
        }}</a>
      </div>

      <div class="container__footer--social">
        <a
          href="https://www.linkedin.com/company/tour-sarkissian-law-offices/mycompany/"
          target="_blank"
          :title="
            store.languageSwitcher == 0
              ? 'DUCAMP-MONOD sur LinkedIn'
              : store.languageSwitcher == 1
              ? 'DUCAMP-MONOD on LinkedIn'
              : 'DUCAMP-MONOD en LinkedIn'
          "
        >
          <svg
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="18.5" cy="18.5" r="18.5" fill="white" />
            <g clip-path="url(#clip0_285_5)">
              <path
                d="M14.4227 13.1125H10.6085C10.4392 13.1125 10.302 13.2498 10.302 13.419V25.6727C10.302 25.8419 10.4392 25.9791 10.6085 25.9791H14.4227C14.592 25.9791 14.7292 25.8419 14.7292 25.6727V13.419C14.7292 13.2498 14.592 13.1125 14.4227 13.1125Z"
                fill="#222222"
              />
              <path
                d="M12.5169 7.021C11.1291 7.021 10 8.14886 10 9.53519C10 10.9221 11.1291 12.0504 12.5169 12.0504C13.9037 12.0504 15.0319 10.9221 15.0319 9.53519C15.0319 8.14886 13.9037 7.021 12.5169 7.021Z"
                fill="#222222"
              />
              <path
                d="M24.1246 12.8081C22.5927 12.8081 21.4602 13.4667 20.7733 14.215V13.4191C20.7733 13.2499 20.6361 13.1127 20.4669 13.1127H16.814C16.6447 13.1127 16.5076 13.2499 16.5076 13.4191V25.6728C16.5076 25.842 16.6447 25.9792 16.814 25.9792H20.62C20.7892 25.9792 20.9264 25.842 20.9264 25.6728V19.61C20.9264 17.5671 21.4813 16.7711 22.9055 16.7711C24.4565 16.7711 24.5797 18.0471 24.5797 19.7152V25.6728C24.5797 25.8421 24.7169 25.9793 24.8862 25.9793H28.6936C28.8628 25.9793 29 25.8421 29 25.6728V18.9515C29 15.9136 28.4208 12.8081 24.1246 12.8081Z"
                fill="#222222"
              />
            </g>
            <defs>
              <clipPath id="clip0_285_5">
                <rect
                  width="19"
                  height="19"
                  fill="white"
                  transform="translate(10 7)"
                />
              </clipPath>
            </defs>
          </svg>
        </a>
      </div>

      <div class="container__footer--copyright">
        <p class="sub-content">
          © {{ currentYear }} SELARL DUCAMP-MONOD & Associés | All rights
          reserved
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { URI } from "@/config/config.js";
import LogoMain from "@/components/LogoMain.vue";
import { useLanguage } from "@/config/stores";
const store = useLanguage();
const currentYear = new Date().getFullYear();

function pRedirect() {
  location.href = URI;
}
</script>

<style></style>
