<template>
    <div class="rendez__vous__btn">
        <a :href="`${ URI }#appointment`">{{ (store.languageSwitcher == 0) ? 'Obtenir un rendez vous' : (store.languageSwitcher == 1) ? 'Get an appointment' : 'Obtener una cita'}}</a>
    </div>
</template>
  
<script setup>
import { URI } from '@/config/config.js'
import { useLanguage } from '@/config/stores';
const store = useLanguage();

</script>
  
<style>

</style>