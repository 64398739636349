<template>
  <nav :class="isMobileNav ? 'nav-sm-screen' : 'nav-grand-screen'">
    <div
      class="nav mobile__overlay"
      :class="
        isMobileNav ? 'mobile__overlay__collapsed' : 'mobile__overlay__hidden'
      "
    >
      <LogoMin
        class="logo__minified"
        :class="!isMobileNav ? 'hide__mobile__nav' : 'mobile__nav'"
        @click="pRedirect()"
        title="home"
      />

      <div class="nav__container">
        <a class="nav__a" :href="`${URI}/`" :class="currentPage('/')">
          {{
            store.languageSwitcher == 0
              ? "l'étude"
              : store.languageSwitcher == 1
              ? "the office"
              : "la notaria"
          }}
        </a>
        <a
          class="nav__a"
          :href="`${URI}/l-equipe`"
          :class="currentPage('/l-equipe')"
        >
          {{
            store.languageSwitcher == 0
              ? "l'équipe"
              : store.languageSwitcher == 1
              ? "our team"
              : "el equipo"
          }}
        </a>
        <a
          class="nav__a"
          :href="`${URI}/metiers`"
          :class="currentPage('/metiers')"
        >
          {{
            store.languageSwitcher == 0
              ? "les métiers"
              : store.languageSwitcher == 1
              ? "the professions"
              : "los oficios"
          }}
        </a>
        <a class="nav__a" href="http://ducamp-monod.site.azko.fr/accueil.htm">
          {{
            store.languageSwitcher == 0
              ? "nos annonces immobilières"
              : store.languageSwitcher == 1
              ? "our property listings"
              : "nuestros anuncios inmobiliarios"
          }}
        </a>

        <div class="dropdown">
          <div
            id="dropEvents"
            class="dropdown__button list__dropdown"
            @click.prevent="mDrop"
          >
            {{
              store.languageSwitcher == 0
                ? "actualités"
                : store.languageSwitcher == 1
                ? "news"
                : "actualidad"
            }}
          </div>

          <div class="dropdown__menu dropdown__menu__ctc">
            <a
              class="dropdown__a"
              :href="`${URI}/evenements`"
              :class="currentPage('/evenements')"
            >
              {{
                store.languageSwitcher == 0
                  ? "évènements"
                  : store.languageSwitcher == 1
                  ? "events"
                  : "eventos"
              }}
            </a>
            <a
              class="dropdown__a"
              :href="`${URI}/publications`"
              :class="currentPage('/publications')"
            >
              {{
                store.languageSwitcher == 0
                  ? "publications"
                  : store.languageSwitcher == 1
                  ? "publications"
                  : "publicaciones"
              }}
            </a>
          </div>
        </div>

        <div class="dropdown">
          <div
            id="dropContacts"
            class="dropdown__button list__dropdown"
            @click.prevent="mDrop"
          >
            {{
              store.languageSwitcher == 0
                ? "contacts"
                : store.languageSwitcher == 1
                ? "contacts"
                : "contactos"
            }}
          </div>

          <div class="dropdown__menu dropdown__menu__ctc">
            <a
              class="dropdown__a"
              :href="`${URI}/#appointment`"
              @click="closeNav()"
            >
              {{
                store.languageSwitcher == 0
                  ? "nous contacter"
                  : store.languageSwitcher == 1
                  ? "contacts us"
                  : "tomar contacto"
              }}
            </a>
            <a
              class="dropdown__a a__special__rule_remove__mobile__border"
              :href="`${URI}/nous-rejoindre`"
              :class="currentPage('/nous-rejoindre')"
            >
              {{
                store.languageSwitcher == 0
                  ? "nous rejoindre"
                  : store.languageSwitcher == 1
                  ? "join us"
                  : "unirse a nosotros"
              }}
            </a>
          </div>
        </div>

        <div class="dropdown">
          <div
            id="dropLanguages"
            class="dropdown__button swap--lang list__dropdown"
            @click.prevent="mDrop"
            title="Changer de langue"
          >
            {{ store.languageSwitcher == 0 ? "fr" : store.languageSwitcher == 1 ? "en" : "sp" }}
          </div>

          <div class="dropdown__menu dropdown__lang">
            <button
              class="dropdown__btn"
              v-for="(ln, i) in languages"
              :key="i"
              :class="ln == store.languageSwitcher ? 'sm__selected' : null"
              @click="switchLang(i)"
            >
              {{ ln }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <MobileNav :call="officeCall ? true : null" @toggle-nav="toggleNav" />
</template>

<script setup>
import LogoMin from "@/components/LogoMin.vue";
import MobileNav from "./MobileNav.vue";
import { ref, onBeforeMount, onMounted, onUnmounted } from "vue";
import { URI } from "@/config/config";
import { useLanguage } from "@/config/stores";

const languages = ref(["fr", "en", "sp"]);
const [store, touchScreen, isMobileNav, officeCall, isOrientation] = [
  useLanguage(),
  ref(),
  ref(false),
  ref(false),
  ref(false),
];

function switchLang(_l) {
  localStorage.setItem("lang", _l);
  store.setlanguageSwitcher(_l);
}

onBeforeMount(() => {
  touchScreen.value =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;
});

onMounted(async () => {
  window.addEventListener("resize", evtHandler, { passive: true });
  window.addEventListener("click", outsideDrop, { passive: true });
});

onUnmounted(async () => {
  window.removeEventListener("resize", evtHandler, { passive: true });
  window.removeEventListener("click", outsideDrop, { passive: true });
});

function evtHandler() {
  document.documentElement.clientWidth >= 768 && isMobileNav.value
    ? (isOrientation.value = true)
    : null;
}

function outsideDrop(e) {
  if (
    e.target.nextSibling != undefined &&
    e.target.nextSibling.classList != undefined &&
    e.target.nextSibling &&
    !e.target.nextSibling.classList.contains("dropdown__menu")
  ) {
    let elem = document.getElementsByClassName("dropdown__reveal");
    elem[0] ? elem[0].classList.toggle("dropdown__reveal") : null;
  } else null;
}

function currentPage(_pathname) {
  let color = "current__page";

  if (window.location.pathname == _pathname) return color;
  else null;
}

function pRedirect() {
  location.href = URI;
}

function mDrop(e) {
  if (!touchScreen.value) return;

  let [className, untoggle, toggleDrop] = ["dropdown__reveal", []];

  for (let drop of document.getElementsByClassName("list__dropdown")) {
    e.target.id != drop.id
      ? untoggle.push(drop.nextSibling)
      : (toggleDrop = drop.nextSibling);
  }

  untoggle.forEach((element) => {
    element.classList.contains(className)
      ? element.classList.remove(className)
      : null;
  });

  toggleDrop.classList.toggle(className);
}

async function toggleNav() {
  isMobileNav.value = !isMobileNav.value;
  isMobileNav.value
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "initial");
}

function closeNav() {
  if (window.location.pathname == "/" && isMobileNav.value) toggleNav();
  officeCall.value = true;
}
</script>

<style></style>
