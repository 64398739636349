<template>
    <div class="feedback">
        <h4 v-if="(props.toggle)" class="feedback__positive">{{(store.languageSwitcher == 0) ? 'Mail envoyé avec succès' : (store.languageSwitcher == 1) ? 'The mail was sent successfully' : 'El correo se ha enviado correctamente' }}</h4>
        <h4 v-if="(!props.toggle)" class="feedback__negative">{{(store.languageSwitcher == 0) ? "Echec de l'envoie, réessayez" : (store.languageSwitcher == 1) ? 'Failed to send, try again' : 'No se ha podido enviar, inténtelo de nuevo' }}</h4>
    </div>
</template>
  
<script setup>
import { watchEffect } from 'vue';
import { useLanguage } from '@/config/stores';
const store = useLanguage();

const props = defineProps(["toggle"]);
const emit = defineEmits(['untoggle']);

watchEffect(async () => {
    await new Promise(r => setTimeout(r, 4000));
    emit('untoggle');
});
</script>
  
<style>

</style>