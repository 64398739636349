import { defineStore } from 'pinia';

function setLanguage(){
    const mainLang = ['fr', 'en', 'sp'];
    let navLang = navigator.language || navigator.userLanguage;
    let userLang = (mainLang.find(lang => lang == navLang.slice(0, 2))) ? navLang.slice(0, 2) : 'en';
    (userLang == 'fr') ? userLang = 0 : (userLang == 'en') ? userLang = 1 : 2;
    return userLang;
}

(!localStorage.getItem("lang")) ? localStorage.setItem("lang", setLanguage()) : null;
(!sessionStorage.getItem('pole')) ? sessionStorage.setItem('pole', 0): null;

export const useLanguage = defineStore('languageSwitcher', {
    state: () => ({
        languageSwitcher: localStorage.getItem("lang"),
    }),
    getters: {
        getlanguageSwitcher: (state) => state.languageSwitcher
    },
    actions: {
        setlanguageSwitcher(lang) {
            this.languageSwitcher = lang;
        },
    },
});

export const usePole = defineStore('pole', {
    state: () => ({
        pole: sessionStorage.getItem("pole"),
    }),
    getters: {
        getPole: (state) => state.pole
    },
    actions: {
        setPole(pole) {
            this.pole = pole;
        },
    },
});